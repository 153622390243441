import * as React from "react";
import * as PageViewLogDataIdTypes from "../../types/pageviewlogdataid";

import { IMappedArticle, IMappedContent } from "../../types/suggestions";
import { IPromotion } from "../../types/promotion";
import { ISuggestionListWithImagesAndPricesProps } from "../../types/suggestionlist";
import { ITopSearchQuery } from "../../types/topsearchquery";

import ArticlePrice from "../article-price/article-price";
import ChevronRight from "../../Assets/svg/Chevron_right_icon";
import PageViewLogDataId from "../../util/pageviewlogdataid/pageviewlogdataid";
import Promotion from "../promotion/promotion";

export default class SuggestionListWithImagesAndPrices extends React.Component<ISuggestionListWithImagesAndPricesProps> {
    private readonly pageViewLogDataId: PageViewLogDataIdTypes.IPageViewLogDataId;

    private navRefs: HTMLElement[] = [];
    private lastNavIndex: number = null;
    public selectedNavIndex: number = null;

    private observer: ResizeObserver | null = null;
    private observedSuggestionRef: HTMLElement | null = null;

    private articleViewMode = "";

    constructor(props) {
        super(props);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.pageViewLogDataId = PageViewLogDataId.getInstance();
    }

    public componentDidMount(): void {
        this.articleViewMode = this.getArticleViewMode();
        const suggestionElement = document.getElementById(`new-suggestions-${this.props.view}`);
        if (suggestionElement) {
            this.observedSuggestionRef = suggestionElement;
            this.updateKeyboardNaviagtionForArticles();
            this.setupResizeObserver();
        }
    }

    public componentDidUpdate(): void {
        this.navRefs = this.navRefs.filter((ref) => ref !== null);
        if (this.selectedNavIndex > this.lastNavIndex) this.selectedNavIndex = null;
        this.removeKeyboardNaviagtionForArticles();
        this.updateKeyboardNaviagtionForArticles();
    }

    public componentWillUnmount(): void {
        if (this.observer) this.observer.disconnect();
    }

    public render() {
        const showPromotion = this.props.mappedFeaturePromotion !== null;
        const showTopSearchQueries = this.props.mappedTopSearchQueries.length > 0;
        const showContentLinks = this.props.mappedContentLinks.length > 0;
        const showArticles = this.props.mappedArticles.length > 0;
        const articleHeadline = this.props.mappedArticles[0];
        const articles = this.props.mappedArticles?.slice(1);
        const hasListItems = showTopSearchQueries || showContentLinks;
        this.lastNavIndex = this.props.navListLength - 1;

        return this.props.isMergedList ? (
            <ul className="suggestions-list-container">
                {showPromotion && (
                    <Promotion
                        promotion={this.props.mappedFeaturePromotion}
                        onClick={this.handlePromotionClick.bind(
                            this,
                            this.props.mappedFeaturePromotion
                        )}
                        key={this.props.mappedFeaturePromotion.navIndex}
                        ref={this.bindRef.bind(this, this.props.mappedFeaturePromotion.navIndex)}
                        style="stretchy"
                    />
                )}
                {showTopSearchQueries &&
                    this.props.mappedTopSearchQueries.map((query, index) =>
                        query.isHeadline ? (
                            <li
                                className={"top-search-query-text not-selectable"}
                                key={query.navIndex}
                                ref={this.bindRef.bind(this, query.navIndex)}
                            >
                                {query.value}
                            </li>
                        ) : (
                            <li
                                className={"top-search-query-item"}
                                key={query.navIndex}
                                ref={this.bindRef.bind(this, query.navIndex)}
                                onMouseEnter={this.handleMouseEnter.bind(this, query.navIndex)}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <a
                                    href={query.target}
                                    onClick={this.handleTopSearchItemClick.bind(this, query)}
                                    dangerouslySetInnerHTML={{
                                        __html: this.highlightText(
                                            query.value,
                                            this.props.searchTerm
                                        )
                                    }}
                                    data-testid={`ssw-top-search-${index}`}
                                />
                            </li>
                        )
                    )}
                {showArticles && (
                    <div className={"article-column-container"}>
                        <span
                            className={`suggestions-text ${articleHeadline.type.toLowerCase()} not-selectable`}
                            key={articleHeadline.navIndex}
                            ref={this.bindRef.bind(this, articleHeadline.navIndex)}
                        >
                            {articleHeadline.title}
                        </span>
                        <div className={`article-container ${this.articleViewMode}`}>
                            {articles.map((article, index) =>
                                article.type === "Articles" ? (
                                    <div
                                        className={`suggestion-item ${article.type.toLowerCase()}`}
                                        key={article.navIndex}
                                        ref={this.bindRef.bind(this, article.navIndex)}
                                        onMouseEnter={this.handleMouseEnter.bind(
                                            this,
                                            article.navIndex
                                        )}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <a
                                            className={`ssw-suggested-${article.type.toLowerCase()}-${index}`}
                                            href={article.target}
                                            onClick={this.handleArticleClick.bind(this, article)}
                                            data-testid={`ssw-suggested-${article.type.toLowerCase()}-${index}`}
                                        >
                                            <div className={"image"}>
                                                <img
                                                    src={article.image}
                                                    alt={this.getImageAltText(article)}
                                                />
                                            </div>
                                            <div className="article-info">
                                                <span
                                                    className="article-name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: article.disableHighlighting
                                                            ? article.title
                                                            : this.highlightText(
                                                                  article.title,
                                                                  this.props.searchTerm
                                                              )
                                                    }}
                                                ></span>
                                                {this.shouldCustomerSeePrices() ? (
                                                    article.price && (
                                                        <ArticlePrice
                                                            prices={article.price}
                                                            priceSettings={
                                                                this.props.priceMandantSettings
                                                            }
                                                            isDesktop={this.isDesktop()}
                                                            priceL10n={this.props.priceL10n}
                                                        />
                                                    )
                                                ) : (
                                                    <div className="article-variants">
                                                        {this.getVariantString(article)}
                                                    </div>
                                                )}
                                            </div>
                                        </a>
                                    </div>
                                ) : (
                                    <div
                                        className="result-button-container"
                                        key={article.navIndex}
                                        ref={this.bindRef.bind(this, article.navIndex)}
                                    >
                                        <a
                                            className="show-results-button"
                                            href={article.target}
                                            onClick={this.handleShowAllResultsClick.bind(
                                                this,
                                                article.target
                                            )}
                                        >
                                            <div className="result-button-text">
                                                <div className="result-button-content">
                                                    {article.title}
                                                </div>
                                                <div className="result-button-icon">
                                                    <ChevronRight />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}
                {showContentLinks &&
                    this.props.mappedContentLinks.map((content, index) =>
                        content.isHeadline ? (
                            <li
                                className={`suggestions-text ${content.type} not-selectable`}
                                key={content.navIndex}
                                ref={this.bindRef.bind(this, content.navIndex)}
                            >
                                {content.title}
                            </li>
                        ) : (
                            <li
                                className={`suggestion-item ${content.type}`}
                                key={content.navIndex}
                                ref={this.bindRef.bind(this, content.navIndex)}
                                onMouseEnter={this.handleMouseEnter.bind(this, content.navIndex)}
                                onMouseLeave={this.handleMouseLeave}
                            >
                                <a
                                    href={content.target}
                                    onClick={this.handleContentClick.bind(this, content)}
                                    dangerouslySetInnerHTML={{
                                        __html: content.disableHighlighting
                                            ? content.title
                                            : this.highlightText(
                                                  content.title,
                                                  this.props.searchTerm
                                              )
                                    }}
                                    data-testid={`ssw-suggested-${content.type}-${index}`}
                                />
                            </li>
                        )
                    )}
            </ul>
        ) : (
            <>
                {hasListItems && (
                    <ul className="suggestions-list-container">
                        {showPromotion && (
                            <Promotion
                                promotion={this.props.mappedFeaturePromotion}
                                onClick={this.handlePromotionClick.bind(
                                    this,
                                    this.props.mappedFeaturePromotion
                                )}
                                style="fixed"
                                key={this.props.mappedFeaturePromotion.navIndex}
                                ref={this.bindRef.bind(
                                    this,
                                    this.props.mappedFeaturePromotion.navIndex
                                )}
                            />
                        )}
                        {showTopSearchQueries &&
                            this.props.mappedTopSearchQueries.map((query, index) =>
                                query.isHeadline ? (
                                    <li
                                        className={"top-search-query-text not-selectable"}
                                        key={query.navIndex}
                                        ref={this.bindRef.bind(this, query.navIndex)}
                                    >
                                        {query.value}
                                    </li>
                                ) : (
                                    <li
                                        className={"top-search-query-item"}
                                        key={query.navIndex}
                                        ref={this.bindRef.bind(this, query.navIndex)}
                                        onMouseEnter={this.handleMouseEnter.bind(
                                            this,
                                            query.navIndex
                                        )}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <a
                                            href={query.target}
                                            onClick={this.handleTopSearchItemClick.bind(
                                                this,
                                                query
                                            )}
                                            dangerouslySetInnerHTML={{
                                                __html: this.highlightText(
                                                    query.value,
                                                    this.props.searchTerm
                                                )
                                            }}
                                            data-testid={`ssw-top-search-${index}`}
                                        />
                                    </li>
                                )
                            )}
                        {showContentLinks &&
                            this.props.mappedContentLinks.map((content, index) =>
                                content.isHeadline ? (
                                    <li
                                        className={`suggestions-text ${content.type} not-selectable`}
                                        key={content.navIndex}
                                        ref={this.bindRef.bind(this, content.navIndex)}
                                    >
                                        {content.title}
                                    </li>
                                ) : (
                                    <li
                                        className={`suggestion-item ${content.type}`}
                                        key={content.navIndex}
                                        ref={this.bindRef.bind(this, content.navIndex)}
                                        onMouseEnter={this.handleMouseEnter.bind(
                                            this,
                                            content.navIndex
                                        )}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <a
                                            href={content.target}
                                            onClick={this.handleContentClick.bind(this, content)}
                                            dangerouslySetInnerHTML={{
                                                __html: content.disableHighlighting
                                                    ? content.title
                                                    : this.highlightText(
                                                          content.title,
                                                          this.props.searchTerm
                                                      )
                                            }}
                                            data-testid={`ssw-suggested-${content.type}-${index}`}
                                        />
                                    </li>
                                )
                            )}
                    </ul>
                )}

                {!hasListItems && showPromotion && (
                    <Promotion
                        promotion={this.props.mappedFeaturePromotion}
                        onClick={this.handlePromotionClick.bind(
                            this,
                            this.props.mappedFeaturePromotion
                        )}
                        style="stretchy"
                        key={this.props.mappedFeaturePromotion.navIndex}
                        ref={this.bindRef.bind(this, this.props.mappedFeaturePromotion.navIndex)}
                    />
                )}

                {showArticles && (
                    <div className={"article-column-container " + (hasListItems ? "padded" : "")}>
                        <span
                            className={`suggestions-text ${articleHeadline.type.toLowerCase()} not-selectable`}
                            key={articleHeadline.navIndex}
                            ref={this.bindRef.bind(this, articleHeadline.navIndex)}
                        >
                            {articleHeadline.title}
                        </span>
                        <div className={`article-container ${this.articleViewMode}`}>
                            {articles.map((article, index) =>
                                article.type === "Articles" ? (
                                    <div
                                        className={`suggestion-item ${article.type.toLowerCase()}`}
                                        key={article.navIndex}
                                        ref={this.bindRef.bind(this, article.navIndex)}
                                        onMouseEnter={this.handleMouseEnter.bind(
                                            this,
                                            article.navIndex
                                        )}
                                        onMouseLeave={this.handleMouseLeave}
                                    >
                                        <a
                                            className={`ssw-suggested-${article.type.toLowerCase()}-${index}`}
                                            href={article.target}
                                            onClick={this.handleArticleClick.bind(this, article)}
                                            data-testid={`ssw-suggested-${article.type.toLowerCase()}-${index}`}
                                        >
                                            <div className={"image"}>
                                                <img
                                                    src={article.image}
                                                    alt={this.getImageAltText(article)}
                                                />
                                            </div>
                                            <div className="article-info">
                                                <span
                                                    className="article-name"
                                                    dangerouslySetInnerHTML={{
                                                        __html: article.disableHighlighting
                                                            ? article.title
                                                            : this.highlightText(
                                                                  article.title,
                                                                  this.props.searchTerm
                                                              )
                                                    }}
                                                ></span>
                                                {this.shouldCustomerSeePrices() ? (
                                                    article.price && (
                                                        <ArticlePrice
                                                            prices={article.price}
                                                            priceSettings={
                                                                this.props.priceMandantSettings
                                                            }
                                                            isDesktop={this.isDesktop()}
                                                            priceL10n={this.props.priceL10n}
                                                        />
                                                    )
                                                ) : (
                                                    <div className={"article-variants"}>
                                                        {this.getVariantString(article)}
                                                    </div>
                                                )}
                                            </div>
                                        </a>
                                    </div>
                                ) : (
                                    <div
                                        className="result-button-container"
                                        key={article.navIndex}
                                        ref={this.bindRef.bind(this, article.navIndex)}
                                    >
                                        <a
                                            className="show-results-button"
                                            href={article.target}
                                            onClick={this.handleShowAllResultsClick.bind(
                                                this,
                                                article.target
                                            )}
                                        >
                                            <div className="result-button-text">
                                                <div className="result-button-content">
                                                    {article.title}
                                                </div>
                                                <div className="result-button-icon">
                                                    <ChevronRight />
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }

    private setupResizeObserver(): void {
        this.observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                if (width > 0 && height > 0) {
                    this.removeKeyboardNaviagtionForArticles();
                    this.updateKeyboardNaviagtionForArticles();
                }
            });
        });

        this.observer.observe(this.observedSuggestionRef);
    }

    private getArticleViewMode(): string {
        const isMobileshop = document.documentElement.classList.contains("mobileshop");
        return this.shouldCustomerSeePrices() && isMobileshop ? "compact" : "expanded";
    }

    private shouldCustomerSeePrices(): boolean {
        return this.props.customerType === "Company" || this.props.customerType === "Surgery";
    }

    private getImageAltText(article: IMappedArticle): string {
        return `${this.props.imageMainView}, ${article.title}`;
    }

    private updateKeyboardNaviagtionForArticles(): void {
        const isTablet = document.documentElement.classList.contains("tablet");
        const isKiosk = document.documentElement.classList.contains("kiosk");
        const innerWidth = window.innerWidth;
        const maxWidthForTwoColumns = 1349; // should have same value as $max-width-for-two-columns
        const visibleArticles = 5;
        const visibleArticlesForWideOnTablet = 4;

        const hasTwoSuggestionContainers =
            this.observedSuggestionRef.querySelector(".suggestions-list-container") &&
            this.observedSuggestionRef.querySelector(".article-column-container");

        const hasTwoSuggestionContainersForGrid =
            this.observedSuggestionRef.querySelector(
                ".suggestions-container:not(.hidden) .suggestions-list-container"
            ) &&
            this.observedSuggestionRef.querySelector(
                ".suggestions-container:not(.hidden) .article-column-container"
            );

        if (hasTwoSuggestionContainersForGrid && this.props.view === "grid") {
            const articles = this.navRefs.filter((ref) => this.hasArticleItemClasses(ref));
            if (articles.length > visibleArticles) {
                articles
                    .slice(visibleArticles)
                    .forEach((article) => article.classList.add("not-selectable", "hidden"));
            }
        } else if (
            hasTwoSuggestionContainers &&
            (this.props.view === "flyout" ||
                (this.props.view === "wide" && innerWidth <= maxWidthForTwoColumns && !isTablet))
        ) {
            const articles = this.navRefs.filter((ref) => this.hasArticleItemClasses(ref));
            if (articles.length > visibleArticles) {
                articles
                    .slice(visibleArticles)
                    .forEach((article) => article.classList.add("not-selectable", "hidden"));
            }
        } else if (isTablet && !isKiosk && this.props.view === "wide") {
            const articles = this.navRefs.filter((ref) => this.hasArticleItemClasses(ref));
            if (articles.length > visibleArticlesForWideOnTablet) {
                articles
                    .slice(visibleArticlesForWideOnTablet)
                    .forEach((article) => article.classList.add("not-selectable", "hidden"));
            }
        }
    }

    private removeKeyboardNaviagtionForArticles(): void {
        this.navRefs
            .filter((ref) => this.hasArticleItemClasses(ref))
            .forEach((article) => article.classList.remove("not-selectable", "hidden"));
    }

    private bindRef(index: number, element): void {
        this.navRefs[index] = element;
    }

    private hasArticleItemClasses(ref: HTMLElement): boolean {
        return (
            ref.classList &&
            ref.classList.contains("articles") &&
            ref.classList.contains("suggestion-item")
        );
    }

    private isDesktop(): boolean {
        const device = this.props.portal.split(".")[0].toLowerCase();
        return device === "portal";
    }

    private getVariantString(article: IMappedArticle): string {
        if (article.availableColorsCount > 0) {
            const colorLabel =
                article.availableColorsCount > 1
                    ? this.props.variantsL10n.colors
                    : this.props.variantsL10n.color;
            return `${article.availableColorsCount} ${colorLabel}`;
        } else if (article.availableModelsCount > 0) {
            const modelLabel =
                article.availableModelsCount > 1
                    ? this.props.variantsL10n.variants
                    : this.props.variantsL10n.variant;
            return `${article.availableModelsCount} ${modelLabel}`;
        } else return "";
    }

    private handleArticleClick(
        article: IMappedArticle,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.handleTouched.bind(this, article.navIndex);

        this.recordArticleUsed(article);

        window.location.href = article.target;
    }

    private handleShowAllResultsClick(
        target: string,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.recordShowAllResultsUsed();

        window.location.href = target;
    }

    private handleContentClick(
        content: IMappedContent,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.handleTouched.bind(this, content.navIndex);

        this.recordContentUsed(content);

        window.location.href = content.target;
    }

    private handlePromotionClick(
        promotion: IPromotion,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.recordFeaturePromotionUsed(promotion);

        window.location.href = promotion.link;
    }

    private handleTopSearchItemClick(
        query: ITopSearchQuery,
        event: React.MouseEvent<HTMLLinkElement>
    ): void {
        event.preventDefault();

        this.handleTouched.bind(this, query.navIndex);

        this.recordTopSearchQueryUsed(query);

        window.location.href = query.target;
    }

    private recordArticleUsed(article: IMappedArticle): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: article.shortLink,
            suggestion: article.title,
            contentType: article.type
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordShowAllResultsUsed(): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: "",
            suggestion: "ShowAllResults",
            contentType: "ShowAllResults"
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordContentUsed(content: IMappedContent): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: content.shortLink,
            suggestion: content.title,
            contentType: content.type
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordFeaturePromotionUsed(promotion: IPromotion): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: promotion.shortLink,
            suggestion: promotion.name,
            contentType: "FeaturePromotion"
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private recordTopSearchQueryUsed(query: ITopSearchQuery): void {
        const data = {
            correlationId: this.pageViewLogDataId.get(),
            culture: this.props.culture,
            portal: this.props.portal,
            searchTerm: this.props.searchTerm,
            shortLink: "",
            suggestion: query.value,
            contentType: query.type
        };
        const jsonData = JSON.stringify(data);
        this.sendTelemetryData(jsonData);
    }

    private sendTelemetryData(jsonData: string): void {
        if (
            window &&
            window.navigator &&
            typeof window.navigator.sendBeacon === "function" &&
            typeof window.Blob === "function"
        ) {
            const blobData = new window.Blob([jsonData], {
                type: "application/json"
            });

            try {
                if (window.navigator.sendBeacon(this.props.trackingEndpoint, blobData)) return;
            } catch (e) {
                // fallback below
            }
        }

        setTimeout(() => {
            fetch(this.props.trackingEndpoint, {
                body: jsonData,
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST"
            });
        }, 0);
    }

    public selectNextListSuggestionEntry(isOpposite: boolean): void {
        const selectedIndex: number = this.selectedNavIndex;
        this.deselectEntry();

        const startIndex = 0;
        let indexToSelect: number = null;

        if (selectedIndex != null) {
            indexToSelect = isOpposite ? selectedIndex - 1 : selectedIndex + 1;
        } else {
            indexToSelect = isOpposite ? this.lastNavIndex : startIndex;
        }

        if (indexToSelect < 0) {
            indexToSelect = this.lastNavIndex;
        } else if (indexToSelect > this.lastNavIndex) {
            indexToSelect = 0;
        }

        if (indexToSelect != null) {
            this.assignSelectedSuggestionEntry(indexToSelect, isOpposite);
        } else {
            this.selectedNavIndex = indexToSelect;
        }
    }

    private assignSelectedSuggestionEntry(indexToSelect: number, isOpposite: boolean): void {
        this.selectEntry(indexToSelect);

        // skip headlines and hidden suggestions
        const skipElement: boolean =
            this.navRefs[indexToSelect].classList?.contains("not-selectable");
        if (skipElement) this.selectNextListSuggestionEntry(isOpposite);
    }

    public triggerListSuggestionRedirect(): void {
        this.navRefs[this.selectedNavIndex].getElementsByTagName("a")[0].click();
    }

    private selectEntry(index: number): void {
        this.selectedNavIndex = index;
        this.navRefs[index].classList === undefined
            ? this.togglePromotionElement(true)
            : this.navRefs[index].classList.add("is-selected");
    }

    private togglePromotionElement(addIsSelected: boolean): void {
        const columnClass = this.props.isMergedList ? ".merged-column" : ".multiple-columns";
        const promotion = this.observedSuggestionRef.querySelector(
            `.suggestions-container${columnClass} .feature-promotion`
        );

        if (promotion) {
            addIsSelected
                ? promotion.classList.add("is-selected")
                : promotion.classList.remove("is-selected");
        }
    }

    public deselectEntry(): void {
        if (this.selectedNavIndex != null) {
            this.navRefs[this.selectedNavIndex].classList === undefined
                ? this.togglePromotionElement(false)
                : this.navRefs[this.selectedNavIndex].classList.remove("is-selected");
            this.selectedNavIndex = null;
        }
    }

    private handleMouseEnter(index: number): void {
        this.deselectEntry();
        this.selectEntry(index);
    }

    private handleMouseLeave(): void {
        this.deselectEntry();
    }

    private handleTouched(index: number): void {
        this.navRefs.forEach((element) => {
            if (element) element.classList.remove("is-touched");
        });

        this.navRefs[index].classList.add("is-touched");
    }

    private highlightText(suggestion: string, searchInput: string): string {
        const inputTerms: string = searchInput
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
            .split(" ")
            .filter((n) => n)
            .join("|");

        const regex = RegExp(inputTerms, "gi");
        return suggestion.replace(regex, "<strong>$&</strong>");
    }
}
